// @flow
export const CELLPHONE_SMALL_WIDTH: number = 320;
export const CELLPHONE_WIDTH: number = 536.5;
export const TABLET_SMALL_WIDTH: number = 767.5;
export const TABLET_WIDTH: number = 991.5;
export const COMPUTER_SMALL_WIDTH: number = 1199.5;

export const AREAS = {
  SALES: 1,
  CREDITS: 2,
  FINANCE: 3,
  ADMINISTRATION: 4
};
export const accountabilityStatus = {
  SENT: 'Enviada',
  APPROVED: 'Aprobada', 
  POSTED: 'Contabilizada'
};

export const daysCheck = {
  CHARGE: 20,
  EMISSION: 40,
  EXPIRATION: 30,
  CHARGE_UYU: 15,
  CHARGE_USD: 90
};

export const internalReceiptStatus = { Pendiente: 1, Aprobado: 2, Rechazado: 3 };
export const paymentMethods = { Cheque: 1, 'Tarjeta de crédito': 2, Efectivo: 3, 'Depósito': 4 };
export const emitters = { Cliente: 1, Tercero: 2 };
export const checkTypes = { 'Al día': 1, Diferido: 2 };
export const banks = ['BBVA','BROU','ITAU','SCOTIBANK','SANTANDER','HSBC','HERITAGE','CITI','BANDES'];
export const ownBanks = ['BBVA','BROU']
export const depositBanks = ['BBVA/RedPagos','BROU/Abitab','SANTANDER']
export const availableFeesCount = [
  { text: '1 cuota', value: 1 },
  { text: '2 cuotas', value: 2 },
  { text: '3 cuotas', value: 3 },
  { text: '6 cuotas', value: 6 }
];
export const availableCreditCardStamps = [{ text: 'MAESTRO' },{ text: 'MASTER CARD' },{ text: 'VISA' }, { text: 'CABAL' }];

export const TIPO_DOC = {
  CI: 1,
  RUT: 2,
  ASSURED: 0
};

export const CFE_TYPE = {
  eTicket: 101,
  notaETicket: 102,
  eFactura: 111,
  notaEFactura: 112
};

export const formatCfeType = (cfeType, isReceipt = false) => {
  switch (cfeType) {
    case CFE_TYPE.eTicket:
      return isReceipt ? 'E-Ticket cobranza' : 'E-Ticket';
    case CFE_TYPE.notaETicket:
      return isReceipt ? 'NC E-Ticket cobranza' : 'NC E-Ticket';
    case CFE_TYPE.eFactura:
      return isReceipt ? 'E-Factura cobranza' : 'E-Factura';
    case CFE_TYPE.notaEFactura:
      return isReceipt ? 'NC E-Factura cobranza' : 'NC E-Factura';
    default:
      return cfeType + '';
  }
};

// Limite de seguridad al validar el usuario contra la libreria de Google recaptcha V3
export const SECURITY_LIMIT = 0.5;

// Limite de días para mostrar notificaciones
export const NOTIFICATION_DAYS = 7;
